<template>
  <div class="border">
    <strong> {{ site }} </strong>
    <li v-for="smoke in formattedSmokes(map!)" style="display: inline;">
      <p @click="openDialogBog(smoke)">
        <div class="hover-effect">
          {{ smoke.description }}
        </div>
      </p>
    </li>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ExecuteSmoke } from '../model/smoke/ExecuteSmoke'

export default defineComponent({
  name: 'SmokeBase',
  props: {
    map: String,
    site: String,
    smokes: Array as () => ExecuteSmoke[],
  },
  data() {
    return {
    };
  },
  methods: {
    formattedSmokes(targetMap: String): ExecuteSmoke[] {
      //this is called when map changes use a watch
      return this.smokes!.filter(s => s.map === targetMap)
    },
    openDialogBog(smoke: ExecuteSmoke) {
      console.log("openDialogBog()")
      this.$emit('openDialog', smoke);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border {
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-left: solid;
  border-right: solid;
  border-color: rgb(101, 101, 101) !important;
}
.hover-effect {
  transition: font-size 0.3s ease;
}
.hover-effect:hover {
  font-size: 1.2em
}
</style>
