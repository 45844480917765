<template>
  <div>
    <div id="smokeList" class="flex" style="margin-top: 2%;">
      <SmokeList v-bind:map=map site="A" v-bind:smokes=aExecute class="smokeList" @openDialog="handleOpenDialog" />
      <SmokeList v-bind:map=map site="Mid" v-bind:smokes=midExecute class="smokeList" @openDialog="handleOpenDialog" />
      <SmokeList v-bind:map=map site="B" v-bind:smokes=bExecute class="smokeList" @openDialog="handleOpenDialog" />
    </div>
  </div>
  <div v-if="selectedSmoke !== undefined || selectedSmoke !== null">
    <Picture v-bind:smoke=selectedSmoke! />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import SmokeList from '@/components/SmokeList.vue'
import { aExecuteSmokes } from '../model/smoke/aExecuteSmokes'
import { bExecuteSmokes } from '../model/smoke/bExecuteSmokes'
import { midExecuteSmokes } from '../model/smoke/midExecuteSmokes'
import { ExecuteSmoke } from '../model/smoke/ExecuteSmoke'
import Picture from '@/components/Picture.vue'

export default defineComponent({
  name: 'SmokeBase',
  props: {
    map: String
  },
  components: {
    SmokeList,
    Picture
  },
  data() {
    const imageScalingFactor: number = 1;
    const showHoverImage = ref(false);

    return {
      aExecute: aExecuteSmokes.smokes,
      midExecute: midExecuteSmokes.smokes,
      bExecute: bExecuteSmokes.smokes,
      selectedSmoke: null as null | ExecuteSmoke,
      imageScalingFactor,
      topMargin: 0,
    }
  },
  created() {
    watch(() => this.selectedSmoke, (newVal, oldVal) => {
      if (this.selectedSmoke?.imageUrl != undefined) {
        this.scrollToEnd()
      }
    });
    watch(() => this.map, (newval, oldval) => {
      if (oldval != newval) {
        this.selectedSmoke = null
      }
    })
    this.imageScalingFactor = this.getStoredImageScale()
    this.setCalculateTopMargin(this.getStoredImageScale() * 100)
  },
  methods: {
    handleOpenDialog(smoke: ExecuteSmoke) {
      this.selectedSmoke = smoke;
    },
    imageScale(scale: number) {
      localStorage.setItem('imageScalingFactor', scale.toString())
      this.imageScalingFactor = scale

      //update view
      const divToScale = document.getElementById('imageScaleDiv');
      if (divToScale) {
        this.setCalculateTopMargin(scale * 100)
        divToScale.style.transform = `scale(${scale})`;
      }
    },
    setCalculateTopMargin(percentage: number) {
      this.topMargin = (percentage !== 100) ? ((percentage - 100) / 100) * 820 / 2 : 0;
    },
    scrollToEnd() {
      const pageHeight = document.body.scrollHeight;
      window.scrollTo({
        top: pageHeight,
        behavior: 'smooth',
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    getStoredImageScale(): number {
      return parseFloat(localStorage.getItem('imageScalingFactor') || '1')
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#smokeList {
  display: flex;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.596);
  transform: translate(-50%, -50%);
}

.smokeList {
  flex: 1;
}
</style>
