import { useRouter } from "vue-router";

export class CookieService {

    getLocalStorage(key: string) {
        return localStorage.getItem(key);
    }


    setLocalStorage(key: string, value: string, expireDate?: Date) {
        localStorage.setItem(key, value)
    }
}