<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-content {
  display: flex;
  align-items: center;
}

.navSpace {
  margin-left: 10px;
}
</style>
