import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f92e0ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "smokeList",
  class: "flex",
  style: {"margin-top":"2%"}
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmokeList = _resolveComponent("SmokeList")!
  const _component_Picture = _resolveComponent("Picture")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SmokeList, {
          map: _ctx.map,
          site: "A",
          smokes: _ctx.aExecute,
          class: "smokeList",
          onOpenDialog: _ctx.handleOpenDialog
        }, null, 8, ["map", "smokes", "onOpenDialog"]),
        _createVNode(_component_SmokeList, {
          map: _ctx.map,
          site: "Mid",
          smokes: _ctx.midExecute,
          class: "smokeList",
          onOpenDialog: _ctx.handleOpenDialog
        }, null, 8, ["map", "smokes", "onOpenDialog"]),
        _createVNode(_component_SmokeList, {
          map: _ctx.map,
          site: "B",
          smokes: _ctx.bExecute,
          class: "smokeList",
          onOpenDialog: _ctx.handleOpenDialog
        }, null, 8, ["map", "smokes", "onOpenDialog"])
      ])
    ]),
    (_ctx.selectedSmoke !== undefined || _ctx.selectedSmoke !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Picture, {
            smoke: _ctx.selectedSmoke!
          }, null, 8, ["smoke"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}