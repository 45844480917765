import { ExecuteSmoke } from "./ExecuteSmoke";

export class bExecuteSmokes {
  static smokes: ExecuteSmoke[] = [
    //Mirage
    {
      map: "Mirage",
      team: "T",
      jumpthrow: false,
      description: "b right arch",
      imageUrl: "maps/Mirage/T_false_b-right-arch_1.jpg",
    },
    {
      map: "Mirage",
      team: "T",
      jumpthrow: true,
      description: "b left arch",
      imageUrl: "maps/Mirage/T_true_b-left-arch_1.jpg",
    },
    {
      map: "Mirage",
      team: "T",
      jumpthrow: false,
      description: "b default",
      imageUrl: "maps/Mirage/T_false_b-default_1.jpg",
    },
    {
      map: "Mirage",
      team: "T",
      jumpthrow: true,
      description: "b window",
      imageUrl: "maps/Mirage/T_true_b-window_1.jpg",
    },
    {
      map: "Mirage",
      team: "T",
      jumpthrow: true,
      description: "b door",
      imageUrl: "maps/Mirage/T_true_b-door_1.jpg",
    },

    //Inferno
    {
      map: "Inferno",
      team: "t",
      jumpthrow: false,
      description: "B ct",
      imageUrl: "maps/Inferno/t_false_B-ct_1.jpg",
    },
    {
      map: "Inferno",
      team: "t",
      jumpthrow: false,
      description: "B coffin",
      imageUrl: "maps/Inferno/t_false_B-coffin_1.jpg",
    },

    //Anubis
    {
      map: "Anubis",
      team: "T",
      jumpthrow: false,
      description: "b con",
      imageUrl: "maps/Anubis/T_false_b-con_1.jpg",
    },
    {
      map: "Anubis",
      team: "T",
      jumpthrow: true,
      description: "b plat",
      imageUrl: "maps/Anubis/T_true_b-plat_1.jpg",
    },
    {
      map: "Anubis",
      team: "T",
      jumpthrow: false,
      description: "b default",
      imageUrl: "maps/Anubis/T_false_b-default_1.jpg",
    },
    {
      map: "Anubis",
      team: "T",
      jumpthrow: true,
      description: "b ct",
      imageUrl: "maps/Anubis/T_true_b-ct_1.jpg",
    },
    {
      map: "Anubis",
      team: "T",
      jumpthrow: true,
      description: "b water",
      imageUrl: "maps/Anubis/T_true_b-water_1.jpg",
    },

    //Ancient
    {
      map: "Ancient",
      team: "T",
      jumpthrow: true,
      description: "b cave",
      imageUrl: "maps/Ancient/T_true_b-cave_1.jpg",
    },
    {
      map: "Ancient",
      team: "T",
      jumpthrow: true,
      description: "b right",
      imageUrl: "maps/Ancient/T_true_b-right_1.jpg",
    },
    {
      map: "Ancient",
      team: "T",
      jumpthrow: true,
      description: "b left",
      imageUrl: "maps/Ancient/T_true_b-left_1.jpg",
    },

    //Vertigo
    {
      map: "Vertigo",
      team: "T",
      jumpthrow: false,
      description: "b default",
      imageUrl: "maps/Vertigo/T_false_b-default_1.jpg",
    },
    {
      map: "Vertigo",
      team: "T",
      jumpthrow: false,
      description: "b kryds",
      imageUrl: "maps/Vertigo/T_false_B-kryds_1.jpg",
    },
    { map: "Dust2", team: "T", jumpthrow: false, description: "B door", imageUrl: "maps/Dust2/T_false_B-door_1.jpg" },
    { map: "Dust2", team: "T", jumpthrow: true, description: "window", imageUrl: "maps/Dust2/T_true_window_1.jpg" }
  ];
}
